import { useState } from 'react';
import Title from '../Title/Title';
import './About.sass'


const About = () => {
    const [active, setActive] = useState(1)
    return ( 
        <main className="About">
            <Title a='About' b='Us'/>
            <div className="cover">
                <div className="left"></div>
                <div className="right">
                    <p className='header'>WE ARE BRIXTONTRADE.COM</p>
                    <p className='longtext'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Beatae cumque vitae inventore quae reiciendis aliquid libero in quas necessitatibus assumenda! Perspiciatis fuga itaque at dolor.</p>
                    <p className='wrapper'>
                        <span className='item' style={{color: active===1? 'red': ''}} onClick={()=> setActive(1)} >OUR MISSION</span>
                        <span className='item' style={{color: active===2? 'red': ''}} onClick={()=> setActive(2)} >OUR ADVANTAGES</span>
                        <span className='iteml' style={{color: active===3? 'red': ''}} onClick={()=> setActive(3)} >OUR GUARANTEES</span>
                    </p>
                    <p className='shorttext'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio voluptates illo nulla.</p>
                    <span className='button'>READ MORE</span>
                </div>
            </div>
        </main>
     );
}
 
export default About;
