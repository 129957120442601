import './Footer.sass'
import { BsFacebook } from 'react-icons/bs' 
import { BsTwitter } from 'react-icons/bs' 
import { BsInstagram } from 'react-icons/bs' 
import { BsYoutube } from 'react-icons/bs' 
import { IoMdCall } from 'react-icons/io' 
import { AiOutlineMail } from 'react-icons/ai' 


 const Footer = () => {
    return ( 
        <main className="Footer">
            <div className="cover">
                <div className="boxa">
                    <p className='header'>Brixton</p>
                    <p>We are <b>BRIXTON</b>, get your earnings to the nextl level in no time</p>
                    <p>4500 Rocky Road California, CA 19010</p>
                </div>
                <div className="boxb">
                    <div className="header">Pages</div>
                    <ul>
                        <li>Home</li>
                        <li>Services</li>
                        <li>Plans</li>
                        <li>About Us</li>
                    </ul>
                </div>
                <div className="boxc">
                <div className="header">Support</div>
                    <ul>
                        <li>FAQ's</li>
                        <li>Support Center</li>
                        <li>Security</li>
                    </ul>
                </div>
                <div className="boxd">
                    <div className="header">Follow us</div>
                    <div className='social'>
                        <BsFacebook className='icon'/>
                        <BsInstagram className='icon'/>
                        <BsTwitter className='icon'/>
                        <BsYoutube className='icon'/>
                    </div>  
                    <div className="wrapper">
                        <p className='contact'>
                            <AiOutlineMail className='cicons'/>
                            <p className='ctext'>info@brixtoninvest.com</p>
                        </p>
                        <p className='contact'>
                            <IoMdCall className='cicons'/>
                            <p className='ctext'>+1 230 550 4500</p>
                        </p>
                    </div>
                </div>
            </div>
            <p className='copy'>Copyright 2021 Brixton. All Rights Reserved</p>
        </main>
     );
 }
  
 export default Footer
 ;