import { Routes, Route } from 'react-router-dom'
import Home from './Pages/Home';


const App = () => {
  
  return ( 
    <main className="App">
      <Routes>
        <Route path='/home' element={<Home />} />
        <Route path='/contact' element={<Home />} />
        <Route path='/why' element={<Home />} />
        <Route path='/services' element={<Home />} />
        <Route path='/plans' element={<Home />} />
        <Route path='/account' element={<Home />} />
      </Routes>
    </main>
   );
}
 


export default App;
