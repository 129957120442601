import './Order.sass'
import { BiDoorOpen } from 'react-icons/bi'
import { BiKey } from 'react-icons/bi'
import { BiPhoneCall } from 'react-icons/bi'




const Order = () => {
    return ( 
        <main className="Order">
            <div className="cover">
                <div className='wrapper'>
                    <BiKey className='img'/>
                    <p className='text' style={{color: '#121544'}}>Easy To Begin</p>
                </div>
                <div className='wrapper'>
                    <BiDoorOpen className='img'/>
                    <p className='text' style={{color: '#121544'}}>Fastest & Safest Investmet</p>
                </div>
                <div className='wrapper'>
                    <BiPhoneCall className='img'/>
                    <p className='text' style={{color: '#121544'}}>Best Customer Service</p>
                </div>
            </div>
        </main>
    );
}
 
export default Order;
