import About from '../Components/About/About';
import Card from '../Components/Card/Card';
import Expert from '../Components/Expert/Expert';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';
import Nav from '../Components/Nav/Nav';
import Order from '../Components/Order/Order';
import './Home.sass'


const Home = () => {
    return ( 
        <main className="Home">
            <Nav />
            <Header />
            <Order />
            <About />
            <Card />
            <Expert />
            <Footer /> 
        </main>
     );
}

export default Home;