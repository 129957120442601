import { NavLink } from 'react-router-dom';
import './Nav.sass'
import { BiUserCircle } from 'react-icons/bi'
import Mobile from '../Mobile/Mobile';
import { motion } from 'framer-motion'
import Img from '../../Images/brx.png'


const Nav = () => {
    const navVAR = [
        {
            hidden:{
                opacity: 0
            },
            visible:{
                opacity: 1,
                transition:{
                    delay: .5,
                    duration: 1
                }
            }
            
        }
    ]
    return ( 
        <main className="Nav">
            <div className="mobile">
                <Mobile />
            </div>
            <motion.nav
            variants={navVAR}
            initial="hidden"
            animate="visible"
            >
                <div className="left">
                    <img src={Img} alt="" width="200" height="100"/>
                </div>



                <div className="middle">
                    <ul >
                        <li><NavLink to='/home'>Home</NavLink></li>
                        <li><NavLink to='/why'>Why Brixton?</NavLink></li>
                        <li><NavLink to='/services'>Services</NavLink></li> 
                        <li><NavLink to='/plans'>Plans</NavLink></li> 
                        <li><NavLink to='/account'>Account</NavLink></li> 
                        <li><NavLink to='/contact'>Contact</NavLink></li>
                    </ul>
                </div>





                <div className="right">
                    <p className='wrapper'>
                        <BiUserCircle  className='login-icon'/>
                        <span>Login / Sign Up</span>
                    </p>
                </div>
            </motion.nav>
        </main>
     );
}
 
export default Nav;
