import Title from '../Title/Title';
import './Expert.sass'

const Expert = () => {
    return ( 
        <main className="Expert">
            <Title a='Our' b='Experts'/>
            <div className="cover">
                <div className='wrapper'>
                    <div className="circle"></div>
                    <p className='name'>Simeone Dylan Perry</p>
                    <p className='text'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                    <p>CEO</p>
                </div>
                <div className='wrapper'>
                    <div className="circle"></div>
                    <p className='name'>Jonas Bailey Moss</p>
                    <p className='text'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                    <p>DIRECTOR</p>
                </div>
                <div className='wrapper'>
                    <div className="circle"></div>
                    <p className='name'>Kevin Traversy Neto </p>
                    <p className='text'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                    <p>CONSULTANT</p>
                </div>
            </div>
        </main>
    );
}
 
export default Expert;
