import './Title.sass'

const Title = ({a, b}) => {
    return ( 
       <main className="Title">
            <div className="contain">
                <div className="line"></div>
                <div>
                    <span className="one">{a} </span>
                    <span className="two">{b}</span>
                </div>
                <div className="line"></div>
            </div>
       </main> 
     );
}
 
export default Title;
