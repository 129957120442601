import './Card.sass'
import { BsCheckCircle } from 'react-icons/bs'
import { BsArrowRight } from 'react-icons/bs'
import { RiVipCrownFill } from 'react-icons/ri'
import { BsCreditCard } from 'react-icons/bs'
import { BiDiamond } from 'react-icons/bi'
import { BsHeart } from 'react-icons/bs'
import Title from '../Title/Title'

const infos = [
    {
        plan: 'Silver plan',
        percent: '30%',
        minimum: '$1,000',
        maximum: '$3,000',
        commission: '7%',
        img: <BsHeart />,
    },
    {
        plan: 'Gold plan',
        percent: '35%',
        minimum: '$3,500',
        maximum: '$6,000',
        commission: '7%',
        img: <BsCreditCard />,
    },
    {
        plan: 'Platinum plan',
        percent: '45%',
        minimum: '$7,000',
        maximum: '$10,000',
        commission: '7%',
        img: <RiVipCrownFill />,
    },
    {
        plan: 'Diamond plan',
        percent: '60%',
        minimum: '$12,500',
        maximum: '$20,000',
        commission: '10%',
        img: <BiDiamond />,
    }
]
const Card = () => {
    return ( 
        <main className="Card">
            <Title a='Our' b='Plans'/>
            <div className="cover">
                {infos.map((info)=>(
                    <div className="card">
                        {info.img}
                        <p className='plan'>{info.plan}</p>
                        <p className='price'>{info.percent}</p>
                        <div className='content'>
                            <p className='wrapper'>
                                <BsCheckCircle className='check'/>
                                <p className='text'>Minimum Investment: {info.minimum}</p>
                            </p>
                            <p className='wrapper'>
                                <BsCheckCircle className='check'/>
                                <p className='text'>Maximum Investment: {info.maximum}</p>
                            </p>
                            <p className='wrapper'>
                                <BsCheckCircle className='check'/>
                                <p className='text'>Referral Commission: {info.commission}</p>
                            </p>
                            <p className='wrapper'>
                                <BsCheckCircle className='check'/>
                                <p className='text'>20 Days Investment</p>
                            </p>
                        </div>
                        <p className='canopy'>
                            <span className='benefit'>See more benefits</span>
                            <BsArrowRight className='arrow'/>
                        </p>
                        <p className='btn'>Check Now</p>
                    </div>
                ))}
                
            </div>
        </main>
     );
}
 
export default Card;

