import './Header.sass'

const Header = () => {
    return ( 
        <main className="Header">
            <div className="cover">
                <p className="big"><span className="clr">your</span> Tested & Trusted <span className="clr">investment</span> partners</p>
                <p className='small'>Get Started</p>
            </div>
        </main>
     );
}

export default Header;