import './Mobile.sass'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import Img from '../../Images/brx.png'
import { HiOutlineSearch } from 'react-icons/hi'
import { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

const Mobile = () => {
    const [opened, setOpened] = useState(false)
    const bottomref = useRef()
    const toggle = () => {
        setOpened(!opened)
        if(opened){
            bottomref.current.style.transform='translateX(100vw)'
        }
        else{
            bottomref.current.style.transform='translateX(0)'
        }
    }
    
    return ( 
        <main className="Mobile">
            <div className="cover">
                <div className="top">
                    <img src={Img} alt="" width="150" height="70"/>
                    {opened ? (
                        <AiOutlineClose className='icon' onClick={toggle}/>
                    ):
                    (
                        <HiOutlineMenuAlt2 className='icon' onClick={toggle}/>

                    )}
                </div>
                <div id="top"></div>
                <div className="bottom" ref={bottomref}>
                    <div className='wrapper'>
                        <HiOutlineSearch className='search'/>
                        <input type="text" placeholder='Search'/>
                    </div>
                    <ul>
                        <li><NavLink to='/home'  onClick={toggle}>Home</NavLink></li>
                        <li><NavLink to='/why' onClick={toggle}>Why Brixton?</NavLink></li>
                        <li><NavLink to='/services' onClick={toggle}>Services</NavLink></li> 
                        <li><NavLink to='/plans' onClick={toggle}>Plans</NavLink></li> 
                        <li><NavLink to='/account' onClick={toggle}>Account</NavLink></li> 
                        <li><NavLink to='/contact' onClick={toggle}>Contact</NavLink></li>
                        <li><NavLink to='/login' onClick={toggle}>Login / Signup</NavLink></li>
                    </ul>
                </div>
            </div>
        </main>
    );
}
 
export default Mobile;
